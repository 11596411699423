import React from 'react';
import {withStyles} from '@material-ui/core';
import styles from 'components/commonStyles';
import PropTypes from 'prop-types';
import {graphql, StaticQuery, Link} from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash/get';
import classnames from 'classnames';
import {navigate} from '@reach/router';

const Case4 = ({classes}) => (
  <StaticQuery
    query={graphql`
      query {
        image: allFile(
          filter: { relativePath: { eq: "other/api-chooser/4.png" } }
        ) {
          edges {
            node {
              id
              childImageSharp {
                id
                fluid(
                  srcSetBreakpoints: [200, 340, 520, 800, 890]
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const img = get(data, 'image.edges[0].node.childImageSharp.fluid', {});
      const small = classnames(classes.textHead, classes.smallString);
      const bigColor = classnames(classes.textColor, classes.bigString);
      const linkColor = classnames(classes.textColor, classes.link);

      const clickButton1 = () => {
        navigate('#simple-articles-consumer');
      };

      const clickButton2 = () => {
        navigate('#simple-articles-provider');
      };

      return (
        <div className={classes.case} id="thin-client-back-office">
          <div className={classes.head}>
            <h4 className={classes.h4Absolute}>
              Use Case #4. Thin client back-office
            </h4>
          </div>
          <p className={classes.textHead} style={{marginBottom: 30}}>
            You have a back-office that works in a browser. That back-office can
            access data inside srv4pos.com.
          </p>
          <Img fluid={img} style={{maxWidth: 643}} />
          <p style={{marginTop: 60, marginBottom: 20}}>
            To manage taxes, categories, products and commodities data see{" "}
            <span className={linkColor} onClick={clickButton1}>
              Use Case #1. Simple Articles consumer
            </span>{" "}
            and{" "}
            <span className={linkColor} onClick={clickButton2}>
              Use Case #2. Simple Articles provder
            </span>
            .
          </p>
          <p style={{marginBottom: 60}}>
            Sales analytics API is available by this API:
          </p>
          <div className={classes.table}>
            <div className={classes.flex30}>
              <h5 className={classes.smallString}> </h5>
              <h5 className={classes.bigString}>JSON</h5>
              <h5 className={classes.bigString}>Java</h5>
            </div>
            <div className={classes.flex30}>
              <p className={classes.smallString}>Per product category:</p>
              <p className={classes.bigString}>GET /categories/stats</p>
              <p className={classes.bigString}>stats()</p>
            </div>
            <div className={classes.flex30}>
              <p className={classes.smallString}>Per product:</p>
              <p className={classes.bigString}>GET /products/stats</p>
              <p className={classes.bigString}>stats()</p>
            </div>
            <div className={classes.flex30}>
              <p className={classes.smallString}>Per cash register:</p>
              <p className={classes.bigString}>GET /registrations/stats</p>
              <p className={classes.bigString}>stats()</p>
            </div>
            <div className={classes.flex30}>
              <p className={classes.smallString}>Detailed (per receipt):</p>
              <p className={classes.bigString}>GET /sales/stats</p>
              <p className={classes.bigString}>stats()</p>
            </div>
          </div>
          <p style={{marginTop: 60}}>
            Basic POS management is presented as list of cash registers by
            calling GET /registrations (getRegistrationList()) . API of advanced
            POS management, such add new POS in run-time is presented at
{" "}
            <Link to="/developers/api-key/">
              <span className={classes.textColor}>API keys</span>
            </Link>{" "}
            page.
          </p>
        </div>
      );
    }}
  />
);

Case4.propTypes = {
  classes: PropTypes.shape({
    case: PropTypes.string.isRequired,
    head: PropTypes.string.isRequired,
    h4Absolute: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    flex30: PropTypes.string.isRequired,
    smallString: PropTypes.string.isRequired,
    bigString: PropTypes.string.isRequired,
    table: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, {withTheme: true})(Case4);
